import React from "react";
import "./SuccessStories.css";
import { Card, Col, Row } from "antd";

const innovationProjects = [
  {
    id: 1,
    videoSrc: "video1.mp4", // replace with actual video path
    projectName: "Project Name 1",
    description:
      "Our 'Zero Poverty' strategy is driven by the P4 approach (Public-Private-People Partnership), aiming for sustainable.",
  },
  {
    id: 2,
    videoSrc: "video2.mp4", // replace with actual video path
    projectName: "Project Name 2",
    description:
      "Our 'Zero Poverty' strategy is driven by the P4 approach (Public-Private-People Partnership), aiming for sustainable.",
  },
  {
    id: 3,
    videoSrc: "video3.mp4", // replace with actual video path
    projectName: "Project Name 3",
    description:
      "Our 'Zero Poverty' strategy is driven by the P4 approach (Public-Private-People Partnership), aiming for sustainable.",
  },
];

const Innovation = () => {
  return (
    <div className="overlap">
      <p className="driving-change-text">
        <span
          style={{
            fontSize: "44px",
            marginRight: "0.3em",
            fontWeight: "700",
            fontFamily: "'Open Sans', sans-serif",
          }}
        >
          Driving Change Through
        </span>
        <span
          style={{
            fontSize: "44px",
            fontWeight: "700",
            fontFamily: "'Open Sans', sans-serif",
          }}
          className="driving-change-text orange-title"
        >
          Innovation
        </span>
      </p>

      <p className="innovation-introduction">
        At KADA, we believe in innovative approaches to foster sustainable
        growth. Our strategies include digital incentives for positive actions,
        smart community hubs, eco-friendly housing, and technology-driven
        education. We aim to transform mindsets and encourage active
        participation in building a model constituency.
      </p>

      <div className="group-wrapper">
        {/* <Row gutter={[16, 16]} className="scrollable-row">
          {innovationProjects.map((project) => (
            <Col
              key={project.id}
              xs={22}
              sm={10}
              md={5}
              lg={8}
              className="story-card"
              style={{ padding: "0px" }}
            >
              <Card
                hoverable
                bordered={false} 
                cover={
                  <video
                    src={project.videoSrc}
                    controls
                    className="story-video"
                  />
                }
                style={{ padding: "0" }} 
              >
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "'Open Sans', sans-serif",
                    lineHeight: "27px",
                  }}
                >
                  {project.description}
                </p>
               
              </Card>
            </Col>
          ))}
        </Row> */}
      </div>
    </div>
  );
};

export default Innovation;
