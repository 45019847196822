import React, { useState } from "react";
import { Col, Image, Row, Menu, Button, Drawer } from "antd";
import { ArrowRightOutlined, MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import apLogo from "../../assets/logos/logo.png";
import { useLocation } from "react-router-dom";

function LandingHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const items = [
    { label: "Home", key: "home" },
    { label: "Vision", key: "vision" },
    { label: "Projects", key: "projects" },
    { label: "Gallery", key: "gallery" },
    { label: "Contact Us", key: "contact-us" },
  ];

  const toggleDrawer = () => {
    setVisible(!visible);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  return (
    <Row
      style={{
        display: "flex",
        position: "sticky",
        top: "0",
        left: "0",
        padding: "10px 20px",
        width: "100%",
      }}
      justify="space-between"
    >
      <Col lg={4} md={6} sm={6} xs={6} style={{ textAlign: "left" }}>
        <Image
          src={apLogo}
          alt="AP Logo"
          preview={false}
          style={{
            height: "8vh",
            width: "auto",
            cursor: "pointer",
          }}
          onClick={() => navigate("/#")}
        />
      </Col>

      <Col lg={12} md={12} sm={0} xs={0}>
        <Menu
          className={`${
            location.pathname === "/gallery" ? "" : "header-menu-items"
          }`}
          mode="horizontal"
          onClick={(item) => {
            navigate(
              `${item.key === "gallery" ? "/gallery" : `/#${item.key}`}`
            );
          }}
          items={items}
          style={{
            display: "flex",
            justifyContent: "center",
            background: "transparent",
            border: "none",
            fontSize: "14px",
            fontFamily: "'Open Sans', sans-serif",
            color: "black",
          }}
        />
      </Col>

      <Col
        lg={4}
        md={6}
        sm={6}
        xs={6}
        style={{
          textAlign: "right",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          type="primary"
          style={{
            background: "#fc6e01",
            marginLeft: "auto",
          }}
          size="large"
          onClick={() => navigate("/login")}
          className="login-button"
        >
          <span
            style={{
              fontSize: "1rem",
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              transition: "all 0.3s ease",
            }}
          >
            Login
            <span
              style={{
                marginLeft: "5px",
                display: "inline-block",
                transition: "transform 0.3s ease",
              }}
              className="arrow-icon"
            >
              <ArrowRightOutlined />
            </span>
          </span>
        </Button>
        <Col
          lg={0}
          md={0}
          sm={6}
          xs={6}
          style={{ textAlign: "right", marginLeft: "10px" }}
        >
          <Button
            type="primary"
            icon={<MenuOutlined />}
            onClick={toggleDrawer}
            size="large"
            style={{
              background: "#fc6e01",
              border: "none",
            }}
          />
        </Col>
      </Col>

      <Drawer
        title="Menu"
        placement="right"
        onClose={closeDrawer}
        visible={visible}
        width={250}
      >
        <Menu
          onClick={(item) => {
            navigate(
              `${item.key === "gallery" ? "/gallery" : `/#${item.key}`}`
            );
            closeDrawer();
          }}
          items={items}
        />
      </Drawer>
    </Row>
  );
}

export default LandingHeader;
