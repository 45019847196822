import React, { useState } from "react";
import { Card, Modal, Image, Typography, Row, Col } from "antd";
import "./SuccessStories.css";
import img1 from "../../assets/success-stories/success-story-1.png";
import img2 from "../../assets/newLanding/success_stories_2.jpg";
import img3 from "../../assets/newLanding/success_stories_3.jpg";
import pdf1 from "../../assets/success-stories/pdf1.png";

const successStories = [
  {
    id: 1,
    imgSrc: img1,
    alt: "Img 1",
    heading:
      "Green Growth: K.M. Venkata Ramana's Journey of Sustainable Abundance",
    subHeading:
      '"I am a master trainer and currently guiding many farmers. I invested ₹30,000 for 7 months and earned ₹3 lakh in returns. In the upcoming 2 months, I will earn ₹1 lakh.”',
    description:
      "K.M. Venkata Ramana is a dedicated and experienced natural farming practitioner from Ankireddypalle village in Ramakuppam mandal. With a total land extent of 3.35 acres, he has fully utilised his land for cultivation, practicing Natural Farming (NF) for the last 7 years.",
    person: "Dhirendra Kumar, Village",
    pdf: pdf1,
  },
  // {
  //   id: 2,
  //   imgSrc: img2,
  //   alt: "Img 2",
  //   description:
  //     'Our "Zero Poverty" strategy is driven by the P4 approach (Public-Private-People Partnership), aiming for sustainable development.',
  //   person: "Dhirendra Kumar, Village",
  //   pdf: null,
  // },
  // {
  //   id: 3,
  //   imgSrc: img3,
  //   alt: "Img 3",
  //   description:
  //     'Our "Zero Poverty" strategy is driven by the P4 approach (Public-Private-People Partnership), aiming for sustainable development.',
  //   person: "Dhirendra Kumar, Village",
  //   pdf: null,
  // },
];

const SuccessStories = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);

  const showModal = (story) => {
    setSelectedStory(story);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedStory(null);
  };

  return (
    <div className="success-container" style={{ padding: "10px" }}>
      <h2 className="success-title">
        Success{" "}
        <span style={{ color: "#111", marginLeft: "10px" }}>Stories</span>
      </h2>
      <div
        className="scrollable-row"
        style={{ flexDirection: "column", gap: "20px" }}
      >
        {successStories.map((story) => (
          <Row
            gutter={[16, 16]}
            className="story-card"
            style={{
              backgroundColor: "#fff",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={story.pdf ? () => showModal(story) : null}
          >
            <Col lg={12} xs={24} sm={24}>
              <img className="image-3" alt="Zero Poverty Strategy" src={img1} />
            </Col>
            <Col lg={1}></Col>
            <Col lg={11} xs={24} sm={24}>
              <div className="group-11">
                <Typography.Title level={2}>{story.heading}</Typography.Title>
                <Typography.Title level={5} style={{ fontStyle: "italic" }}>
                  {story.subHeading}
                </Typography.Title>

                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    textAlign: "left",
                    fontFamily: "'Open Sans', sans-serif",
                  }}
                >
                  {story.description}
                </p>
              </div>
            </Col>
          </Row>
        ))}
      </div>

      <Modal footer={null} open={isModalOpen} onCancel={handleCancel}>
        {selectedStory && selectedStory.pdf ? (
          <Image
            style={{ padding: "20px 0px" }}
            src={selectedStory.pdf}
            preview={false}
          />
        ) : (
          <p>No PDF available</p>
        )}
      </Modal>
    </div>
  );
};

export default SuccessStories;
