import React, { useState } from "react";
import "./NewVision.css";
import { Card, Col, Image, Row, Modal, Typography } from "antd";
// import posterThumbnail from "../../assets/newLanding/thumbnail_ChandrababuNaidu.avif";
import naturalFarming from "../../assets/landing-pdfs/KADA_Natural_Farming_Cover.jpg";
import swarnaKumpam from "../../assets/landing-pdfs/Swarna Kuppam Vision Cover Page.jpg";
import swarnaKumpamPDF from "../../assets/landing-pdfs/Swarna-Kuppam-Vision-2029_Final-Booklet_04-01-2025.pdf";
import naturalFarmingPDF from "../../assets/landing-pdfs/KADA_Natural_Farming_02122025_final new.pdf";
import newsSwarnaKuppamVision from "../../assets/landing-pdfs/10745_6_1_2025_19_45_50_2_IMG_20250106_WA0032.jpeg";
import programmesIcon from "../../assets/icons/Programmes.svg";
import foundationStoneIconn from "../../assets/icons/Foundation stone.svg";
import mouSignedIcon from "../../assets/icons/MOUs signed.svg";
import uniqueIntiativesIcon from "../../assets/icons/Unique Intiative.svg";

const modalContents = {
  programmes: {
    title: "Programmes",
    content: [
      "Solarization programme in Nadimuru Village",
      "Jananayakudu grievance portal",
    ],
  },
  foundationStones: {
    title: "Foundation Stones Laid",
    content: [
      "M/S Mother Dairy Fruit & Vegetables Pvt. Ltd.",
      "Shreeja Mahila Milk Producer Organization",
      "NTR Sports Complex completion",
      "KADA Integrated Command Control Centre",
      "Municipality Development Works",
    ],
  },
  mouSigned: {
    title: "MoUs Signed",
    content: [
      "ALEAP - Mahila Sakhti Bhavan",
      "Big Basket",
      "IIT Kanpur - Net Zero Constituency",
    ],
  },
  uniqueInitiatives: {
    title: "Unique Initiatives",
    content: [
      "<b>Care N Grow</b> - Implementing the Early Childhood Development (ECD) project in 428 Anganwadi centres.",
      "<b>Ezimart</b> - To establish 50 to 100 micro kirana (general) stores to provide self-employment opportunities for women entrepreneurs.",
      "<b>Taqanal Energy</b> - Retrofitting of traditional APSRTC buses into e-buses by battery swapping technology.",
      "<b>Crack Academy</b> - Crack Academy Centre is a coaching centre that offers courses for the preparation of various government examinations, and skill development courses.",
      "<b>Tata MD Digital Nerve Centre</b> - Integrate healthcare centres, a nerve centre, and a virtual network of care providers using technology to enhance primary healthcare access and coordination.",
      "<b>1M1B</b> - Green Skills Career Readiness Centre",
    ],
  },
};

const NewVision = () => {
  const { Meta } = Card;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [modalHeader, setModalHeader] = useState("");

  const showModal = (key) => {
    setModalContent(modalContents[key]?.content);
    setModalHeader(modalContents[key]?.title);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const visionItems = [
    { cover: swarnaKumpam, pdf: swarnaKumpamPDF },
    { cover: naturalFarming, pdf: naturalFarmingPDF },
    {
      cover: newsSwarnaKuppamVision,
      pdf: "https://www.thehindu.com/news/national/andhra-pradesh/chandrababu-naidu-unveils-swarna-kuppam-vision-2029-to-transform-kuppam-into-a-model-region-with-innovative-strategies/article69068298.ece",
    },
  ];

  return (
    <div className="vision-container" id="vision">
      <Row gutter={[16, 16]} style={{ height: "auto" }}>
        <Col xs={24} lg={11} style={{ display: "flex", padding: "0px" }}>
          <div className="group-9">
            <p className="vision-header">
              <span>Vision </span>
              <span className="orange-title">2029</span>
            </p>
            <p className="vision-description">
              At KADA, our vision is to nurture a thriving community where every
              citizen has access to quality services, economic opportunities,
              and a high standard of living. Through strategic initiatives and
              collaborative efforts, we aim to promote equitable growth, drive
              progress, and establish Kuppam as a beacon of sustainable
              development in the region.
            </p>
            <div>
              <div className="overlap-group-6">
                <p className="vision-goal" style={{ fontSize: "25px" }}>
                  KADA will be a Zero Poverty region by 2029
                </p>
                <div className="vision-cm-name">
                  Shri. N. Chandrababu Naidu,{" "}
                  <span className="vision-cm-title">
                    Hon'ble Chief Minister of Andhra Pradesh
                  </span>
                </div>
              </div>
              <div className="overlap-group-6-1">
                <p className="vision-cm-title">
                  Hon’ble Chief Minister launched the{" "}
                  <b style={{ fontSize: "17px" }}>
                    “Swarna Kuppam Vision 2029”
                  </b>{" "}
                  and{" "}
                  <b style={{ fontSize: "17px" }}>
                    “Kuppam Natural Farming Vision 2029”
                  </b>{" "}
                  on 6th January 2025.
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={13} style={{ display: "flex" }}>
          <div className="vision-video-container">
            <Card
              cover={
                <video
                  src={"https://pat.vsws.co.in/videos/kada-video.mp4"}
                  controls
                  className="story-video"
                />
              }
              style={{ height: "100%" }}
            >
              <Meta
                style={{ fontFamily: "sans-serif", padding: "5px 0px" }}
                title="Making Kuppam a Zero Poverty Region"
                description="AP Hon'ble CM Chandrababu Naidu shares his vision on reviving KADA during a public meeting at Kuppam."
              />
            </Card>
          </div>
        </Col>
      </Row>

      <Row
        gutter={[16, 16]}
        className="images-row"
        style={{ minHeight: "320px", marginTop: "80px" }}
      >
        {visionItems.map((doc, index) => (
          <Col key={index} lg={8} md={12} sm={24} xs={24}>
            <a
              href={doc.pdf}
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-card"
            >
              <div className="pdf-container">
                <img
                  src={doc.cover}
                  alt={`PDF ${index + 1}`}
                  className="pdf-cover"
                />
              </div>
            </a>
          </Col>
        ))}
      </Row>

      <Row
        gutter={[32, 16]}
        style={{ marginTop: "80px", paddingRight: "30px" }}
      >
        {[
          {
            title: "Programmes",
            count: "02",
            icon: programmesIcon,
            key: "programmes",
          },
          {
            title: "Foundation Stones Laid",
            count: "06",
            icon: foundationStoneIconn,
            key: "foundationStones",
          },
          {
            title: "MoUs Signed",
            count: "03",
            icon: mouSignedIcon,
            key: "mouSigned",
          },
          {
            title: "Unique Initiatives",
            count: "06",
            icon: uniqueIntiativesIcon,
            key: "uniqueInitiatives",
          },
        ].map((item) => (
          <Col span={6} md={6} xs={24} key={item.key}>
            <Card className="icon-cards" onClick={() => showModal(item.key)}>
              <div className="icon-container">
                <div className="icon-image">
                  <Image src={item.icon} height={85} preview={false} />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography.Title
                    level={4}
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    {item.title}
                  </Typography.Title>
                </div>
                <Typography.Title
                  style={{
                    margin: "0px",
                    padding: "0px",
                    color: "#fc6e01",
                    fontSize: "50px",
                  }}
                >
                  {item.count}
                </Typography.Title>
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        title={
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>{modalHeader} </p>
        }
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ minWidth: "80%" }}
        centered
      >
        <ul>
          {modalContent.map((content, index) => (
            <li
              key={index}
              style={{ fontSize: "18px", marginBottom: "10px" }}
              dangerouslySetInnerHTML={{ __html: content }}
            ></li>
          ))}
        </ul>
      </Modal>
    </div>
  );
};

export default NewVision;
